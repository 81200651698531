fieldset {
	/* Reset fieldset */
	padding: 0;
	margin: 0;
	border: 0;
}

.form-label,
.form-switch,
.form-slider {
	color: color(label);
	@include font-weight(label);

}

.form-label {
	@include font-size(label);
}

.form-input,
.form-select,
.form-checkbox input,
.form-radio input {
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: color-bg(input);
	border-width: $button-form--border-width;
	border-style: $button-form--border-style;
	border-color: color-border(input);

	&:hover {
		border-color: color-border(input-hover);
	}

	&:active,
	&:focus {
		outline: none;
		border-color: color-border(input-focus);
		background-color: color-bg(input-focus);
	}

	&[disabled] {
		cursor: not-allowed;
		background-color: color-bg(input-disabled);
		border-color: color-border(input-disabled);
	}

	&.form-error {
		border-color: get-color(alert, error);
	}

	&.form-warning {
		border-color: get-color(alert, warning);
	}

	&.form-success {
		border-color: get-color(alert, success);
	}
}

.form-input,
.form-select {
	display: block;
	@include font-size(input);
	@include font-weight(input);
	padding: (($button-form--height - get-line-height(input) - ($button-form--border-width * 2)) * 0.5) ($form--padding-h - $button-form--border-width);
	height: $button-form--height;
	border-radius: $form--radius;
	color: color(input);
	box-shadow: none;
	max-width: 100%;
	width: 100%;
}

.form-input {

	&::placeholder {
		color: color(input-placeholder);
	}

	&::-ms-input-placeholder {
		color: color(input-placeholder);
	}
}

textarea.form-input {
	height: auto;
	resize: vertical;
}

.form-select {
	padding-right: ($form--padding-h - $button-form--border-width) * 2 + 16;
	background-image: inline-svg('<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 11.4L2.6 6 4 4.6l4 4 4-4L13.4 6 8 11.4z" fill="' + color-icon(select-arrow) + '" fill-rule="evenodd"/></svg>');
	background-position: right ($form--padding-h - $button-form--border-width) center;
	background-repeat: no-repeat;
	background-size: 16px 16px;
}

.form-select-sm {
	padding-right: ($form-sm--padding-h - $button-form--border-width) * 2 + 16;
	background-position: right ($form-sm--padding-h - $button-form--border-width) center;
}

.form-select-sm,
.form-input-sm {
	padding: (($button-form-sm--height - get-line-height(input) - ($button-form--border-width * 2)) * 0.5) ($form-sm--padding-h - $button-form--border-width);
	height: $button-form-sm--height;
}

.has-icon-left,
.has-icon-right {
	position: relative;

	svg {
		pointer-events: none;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

.has-icon-left {

	.form-input {
		padding-left: ($form--padding-h - $button-form--border-width) * 2 + 16;

		+ svg {
			left: $form--padding-h - $button-form--border-width;
		}
	}

	.form-input-sm {
		padding-left: ($form-sm--padding-h - $button-form--border-width) * 2 + 16;

		+ svg {
			left: $form-sm--padding-h - $button-form--border-width;
		}
	}
}

.has-icon-right {

	.form-input {
		padding-right: ($form--padding-h - $button-form--border-width) * 2 + 16;

		+ svg {
			right: $form--padding-h - $button-form--border-width;
		}
	}

	.form-input-sm {
		padding-right: ($form-sm--padding-h - $button-form--border-width) * 2 + 16;

		+ svg {
			right: $form-sm--padding-h - $button-form--border-width;
		}
	}
}

.form-checkbox,
.form-radio,
.form-switch {
	cursor: pointer;
}

.form-checkbox,
.form-radio,
.form-switch,
.form-slider {
	@include font-size(check-radio);
	@include font-weight(check-radio);
}

.form-checkbox,
.form-radio {
	color: color(check-radio);
}

.form-checkbox,
.form-radio {
	position: relative;
	padding-left: 24px;

	input {
		position: absolute;
		left: 0;
		top: ( ( get-line-height(check-radio) - 16 ) * 0.5 ) - 1; // -1 is for optical adjustement
		width: 16px;
		height: 16px;
		cursor: pointer;

		&:checked {
			background-color: color-bg(check-radio-checked) !important;
			border-color: color-bg(check-radio-checked) !important;
			background-position: 50%;
		}
	}
}

.form-checkbox {

	input {
		border-radius: 2px;

		&:checked {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxwYXRoIGQ9Ik0xMS40IDVMMTAgMy42bC00IDQtMi0yTDIuNiA3IDYgMTAuNHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
		}
	}
}

.form-radio {

	input {
		border-radius: 50%;

		&:checked {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBmaWxsPSIjRkZGIiBjeD0iNyIgY3k9IjciIHI9IjMiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
		}
	}
}

.form-switch {
	display: inline-flex;
	align-items: center;
	user-select: none;

	input {
		clip: rect(0, 0, 0, 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		position: absolute;
		width: 1px;
	}

	.form-switch-icon {
		display: block;
		position: relative;
		min-width: 60px;
		width: 60px;
		height: 32px;
		border-radius: 16px;
		background: color-bg(switch);

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 2px;
			left: 2px;
			width: 28px;
			height: 28px;
			border-radius: 14px;
			background: get-color(light, 1);
			transition: all .15s ease-out;
		}
	}

	input:checked {

		+ .form-switch-icon {
			background: color-bg(switch-checked);

			&::before {
				left: 30px;
			}
		}
	}
	
	> span {

		&:not(.form-switch-icon) {
			order: -1;
			margin-right: 12px;

			&:last-child {
				order: initial;
				margin-left: 12px;
				margin-right: 0;
			}
		}
	}
}

.form-slider {

	> span {
		display: block;
	}

	input[type=range] {
		-moz-appearance: none;
		-webkit-appearance: none;
		background: color-bg(range-track);
		border-radius: 3px;
		height: 6px;
		width: 100%;
		margin-top: ($range-thumb-size - 6px) * 0.5;
		margin-bottom: ($range-thumb-size - 6px) * 0.5;
		outline: none;

		&::-webkit-slider-thumb {
			appearance: none;
			-webkit-appearance: none;
			background-color: color-bg(range-thumb);
			background-image: inline-svg('<svg width="12" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M8 .5v7L12 4zM0 4l4 3.5v-7z" fill="' + color-icon(range) + '" fill-rule="nonzero"/></svg>');
			background-position: center;
			background-repeat: no-repeat;
			border: 0;
			border-radius: 50%;
			cursor: pointer;
			height: $range-thumb-size;
			width: $range-thumb-size;			
		}

		&::-moz-range-thumb {
			background-color: color-bg(range-thumb);
			background-image: inline-svg('<svg width="12" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M8 .5v7L12 4zM0 4l4 3.5v-7z" fill="' + color-icon(range) + '" fill-rule="nonzero"/></svg>');
			background-position: center;
			background-repeat: no-repeat;
			border: 0;
			border: none;
			border-radius: 50%;
			cursor: pointer;
			height: $range-thumb-size;
			width: $range-thumb-size;
		}
		
		&::-ms-thumb {
			background-color: color-bg(range-thumb);
			background-image: inline-svg('<svg width="12" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M8 .5v7L12 4zM0 4l4 3.5v-7z" fill="' + color-icon(range) + '" fill-rule="nonzero"/></svg>');
			background-position: center;
			background-repeat: no-repeat;
			border: 0;
			border-radius: 50%;
			cursor: pointer;
			height: $range-thumb-size;
			width: $range-thumb-size;
		}
		
		&::-moz-focus-outer {
			border: 0;
		}
	}
}

.invert-color {

	.form-label,
	.form-switch,
	.form-slider {
		color: color(label-inverse);
	}

	.form-input,
	.form-select,
	.form-checkbox input,
	.form-radio input {
		background-color: color-bg(input-inverse);
		border-color: color-border(input-inverse);

		&:hover {
			border-color: color-border(input-hover-inverse);
		}

		&:active,
		&:focus {
			border-color: color-border(input-focus-inverse);
			background-color: color-bg(input-focus-inverse);
		}

		&[disabled] {
			background-color: color-bg(input-disabled-inverse);
			border-color: color-border(input-disabled-inverse);
		}
	}

	.form-input,
	.form-select {
		color: color(input-inverse);
	}

	.form-input {

		&::placeholder {
			color: color(input-ph-inverse);
		}

		&::-ms-input-placeholder {
			color: color(input-ph-inverse);
		}
	}

	.form-select {
		background-image: inline-svg('<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 11.4L2.6 6 4 4.6l4 4 4-4L13.4 6 8 11.4z" fill="' + color-icon(select-arrow-inverse) + '" fill-rule="evenodd"/></svg>');
	}
	
	.form-checkbox,
	.form-radio {
		color: color(check-radio-inverse);
	}

	.form-checkbox,
	.form-radio {

		input {

			&:checked {
				background-color: color-bg(check-checked-inverse) !important;
				border-color: color-bg(check-checked-inverse) !important;
			}
		}
	}

	.form-switch {

		.form-switch-icon {
			background: color-bg(switch-inverse);

		}

		input:checked {

			+ .form-switch-icon {
				background: color-bg(switch-checked-inverse);
			}
		}
	}
	
	.form-slider {

		input[type=range] {
			background: color-bg(range-track-inverse);

			&::-webkit-slider-thumb {
				background-color: color-bg(range-thumb-inverse);
				background-image: inline-svg('<svg width="12" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M8 .5v7L12 4zM0 4l4 3.5v-7z" fill="' + color-icon(range-inverse) + '" fill-rule="nonzero"/></svg>');
			}

			&::-moz-range-thumb {
				background-color: color-bg(range-thumb-inverse);
				background-image: inline-svg('<svg width="12" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M8 .5v7L12 4zM0 4l4 3.5v-7z" fill="' + color-icon(range-inverse) + '" fill-rule="nonzero"/></svg>');
			}
			
			&::-ms-thumb {
				background-color: color-bg(range-thumb-inverse);
				background-image: inline-svg('<svg width="12" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M8 .5v7L12 4zM0 4l4 3.5v-7z" fill="' + color-icon(range-inverse) + '" fill-rule="nonzero"/></svg>');
			}
		}
	}	
}

.form-group {
	display: flex;

	.form-input {
		flex-grow: 1;
		flex-shrink: 1;
		margin-right: $button-form--group-padding;

		@if ( $button-form--group-padding == null ) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right-width: 0;
		}
	}

	.button {
		padding-left: $button-w-form--padding-h;
		padding-right: $button-w-form--padding-h;
		flex-shrink: 0;

		@if ( $button-form--group-padding == null ) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.button-sm {
		padding-left: $button-sm-w-form--padding-h;
		padding-right: $button-sm-w-form--padding-h;
	}
}

.form-hint {
	@include font-size(hint);
	margin-top: $form-hint--margin;
}

@include media( '<=medium' ) {

	.form-group-desktop {

		.form-input {
			margin-bottom: $button-form--group-padding;

			@if ( $button-form--group-padding == null ) {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom-width: 0;
			}
		}

		.button {
			width: 100%;

			@if ( $button-form--group-padding == null ) {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}
}

@include media( '>medium' ) {

	.form-group-desktop {
		display: flex;

		.form-input {
			flex-grow: 1;
			flex-shrink: 1;
			margin-right: $button-form--group-padding;

			@if ( $button-form--group-padding == null ) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-right-width: 0;
			}
		}

		.button {
			padding-left: $button-w-form--padding-h;
			padding-right: $button-w-form--padding-h;
			flex-shrink: 0;

			@if ( $button-form--group-padding == null ) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

		.button-sm {
			padding-left: $button-sm-w-form--padding-h;
			padding-right: $button-sm-w-form--padding-h;
		}
	}
}
