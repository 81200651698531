.is-boxed {
	background: color-bg(body-outer);

	.body-wrap {
		max-width: $container--width-boxed;
		margin: 0 auto;
		box-shadow: color-bg(body-shadow);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: color-bg(body);
			z-index: -10;
		}
	}
}

.body-wrap {
	position: relative;
	overflow: hidden;
	// Sticky footer
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.site-content {
	flex: 1 0 auto;
}
