// Downloads tiles (core/sections/_downloads-tiles.scss) ---------
$downloads-tiles--padding-t__mobile: 		null;		// downloads tiles, top padding (mobile)
$downloads-tiles--padding-b__mobile:		null;		// downloads tiles, bottom padding (mobile)
$downloads-tiles--padding-t__desktop: 		null;		// downloads tiles, top padding (desktop)
$downloads-tiles--padding-b__desktop: 		null;		// downloads tiles, bottom padding (desktop)
$downloads-tiles-header--padding__mobile: 	null;		// downloads tiles header, bottom padding (mobile)
$downloads-tiles-header--padding__desktop: 	null;		// downloads tiles header, bottom padding (desktop)
$downloads-tiles-items--padding__mobile: 	32px;		// padding between downloads tiles (mobile)
$downloads-tiles-items--padding__desktop: 	64px;		// padding between downloads tiles (desktop)
$downloads-tiles-item--width: 				317px;		// downloads tiles item, width
$downloads-tiles-item--inner-padding-v: 		0;		// downloads tiles item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$downloads-tiles-item--inner-padding-h: 		0;		// downloads tiles item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
